/* Css For Rivox */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header
5. Banner Style One 
6. Feature Section
7. About Section
9. Service Section
8. Work Section
10. Testimonial Section
11. Pricing Section
12. Subscrive Section
14. Main Footer
15. Header Style Two
16. Banner Style Two
19. Clients Section
20. About Style Two
21. Feature Style Two
22. Portfolio Section
23. Testimonial Style Two
24. Pricing Style Two
25. Fact Counter
26. News Section
27. Subscribe Style Two
28. Banner Style Three
29. Feature Style Three
30. Testimonial Style Three
31. Feature Style Four
32. Integration Section
33. Banner Style Four
34. Service Style Three
35. Counter Style Two
36. Feature Style Five
37. Feature Style Six
38. Testimonial Style Four
39. Pricing Style Three
40. Cta Section
41. Banner Style Five
42. Service Style Four
43. Chooseus Section
44. Testimonial Style Five
45. Pricing Style Four
54. Error Page


**********************************************/

/*** 

====================================================================
  Reset
====================================================================

 ***/
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}


/*** 

====================================================================
  Global Settings
====================================================================

 ***/


body {
    font-size: 16px;
    color: #848484;
    line-height: 26px;
    font-weight: 400;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

@media (min-width:1200px) {
    .container {
        max-width: 1170px;
        padding: 0px;
    }
}

.large-container {
    position: static;
    max-width: 1600px;
    padding: 0px;
    margin: 0 auto;
}

.container-fluid {
    padding: 0px;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.small-container {
    max-width: 680px;
    margin: 0 auto;
}

.boxed_wrapper {
    position: relative;
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
}


a {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    transition: all 500ms ease;
}

    a:hover {
        text-decoration: none;
        outline: none;
    }

input, button, select, textarea {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

ul, li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

input {
    transition: all 500ms ease;
}

    button:focus,
    input:focus,
    textarea:focus {
        outline: none;
        box-shadow: none;
        transition: all 500ms ease;
    }

p {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    line-height: 26px;
    color: #848484;
    margin-bottom: 0px;
    font-style: normal;
    transition: all 500ms ease;
}

h1, h2, h3, h4, h5, h6 {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    color: #09092b;
    font-weight: 400;
    margin: 0px;
    transition: all 500ms ease;
}


.centred {
    text-align: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

figure {
    margin: 0px;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

@keyframes pulse {
    50% {
        box-shadow: 0 0 0 20px rgb(149 255 78 / 50%)
    }
}

/** button **/

.btn-style-one {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    padding: 15px 25px 14px 26px;
    background: #73a621;
    line-height: 26px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 3px;
    text-transform: capitalize;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;
    z-index: 1;
}

    .btn-style-one:hover {
        background: #8bca24;
        color: #ffffff;
    }

.btn-style-two {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    padding: 15px 30px 14px 31px;
    background: #8bca24;
    line-height: 26px;
    color: #ffffff;
    font-weight: 600;
    border-radius: 3px;
    text-transform: capitalize;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;
    z-index: 1;
}

    .btn-style-two:hover {
        background: #73a621;
        color: #ffffff;
    }

.btn-style-three {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    padding: 13px 29px 12px 29px;
    background: transparent;
    border: 2px solid #8bca24;
    line-height: 26px;
    color: #101048;
    font-weight: 600;
    border-radius: 3px;
    text-transform: capitalize;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;
    z-index: 1;
}

    .btn-style-three:hover {
        background: #8bca24;
        color: #ffffff;
    }

.btn-style-four {
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    padding: 13px 29px 12px 29px;
    background: transparent;
    border: 2px solid #ebecf0;
    line-height: 26px;
    color: #3b3b3b;
    font-weight: 600;
    border-radius: 3px;
    text-transform: capitalize;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;
    z-index: 1;
}

    .btn-style-four:hover {
        background: #8bca24;
        border-color: #8bca24;
        color: #ffffff;
    }


.pagination {
    position: relative;
    display: block;
}

    .pagination li {
        display: inline-block;
        margin: 0px 11px;
    }

        .pagination li:first-child,
        .pagination li:last-child {
            margin: 0px;
        }

        .pagination li a {
            position: relative;
            display: inline-block;
            font-size: 24px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            text-align: center;
            color: #3b3b3b;
            transition: all 500ms ease;
        }

        .pagination li:first-child a,
        .pagination li:last-child a {
            font-size: 20px;
        }

        .pagination li a:hover,
        .pagination li a.active {
            color: #8bca24;
        }

.sec-pad {
    padding: 154px 0px 120px 0px;
}

.scroll-top {
    position: fixed;
    right: -10%;
    bottom: 50px;
    font-size: 15px;
    line-height: 20px;
    z-index: 99;
    color: #8bca24;
    background: transparent;
    text-align: center;
    cursor: pointer;
    transition: all 900ms;
}

    .scroll-top .text {
        position: relative;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }

    .scroll-top.open {
        right: 30px;
    }

.sec-title h4 {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #848484;
    text-transform: uppercase;
    margin-bottom: 21px;
}

.sec-title h1 {
    position: relative;
    display: block;
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    color: #3b3b3b;
}




/*** 

====================================================================
                        Home-Page-One
====================================================================

***/


/** main-header **/

.main-header {
    position: relative;
    left: 0px;
    top: 0px;
    background: #ffffff;
    z-index: 999;
    width: 100%;
    transition: all 500ms ease;
}

.sticky-header {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    left: 0px;
    top: 0px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    z-index: 0;
    transition: all 500ms ease;
}

.fixed-header .sticky-header {
    z-index: 999;
    opacity: 1;
    visibility: visible;
    animation-name: fadeInDown;
    animation-duration: 500ms;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

.main-header .outer-container {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
}

    .main-header .outer-container .main-box {
        position: relative;
        display: block;
        padding: 0px 100px 0px 80px;
    }

        .main-header .outer-container .main-box .logo-box {
            position: relative;
            float: left;
        }

            .main-header .outer-container .main-box .logo-box .logo {
                position: relative;
                display: inline-block;
                padding: 26px 0px;
            }

                .main-header .outer-container .main-box .logo-box .logo img {
                    height: 25px;
                    margin: 30px 0;
                }

        .main-header .outer-container .main-box .nav-outer {
            position: relative;
            float: right;
        }

            .main-header .outer-container .main-box .nav-outer .menu-area {
                position: relative;
                float: left;
                margin-right: 70px;
            }

            .main-header .outer-container .main-box .nav-outer .outer-box {
                position: relative;
                float: left;
                margin-top: 45px;
            }

                .main-header .outer-container .main-box .nav-outer .outer-box .header-flyout-searchbar {
                    position: relative;
                    display: inline-block;
                }

                    .main-header .outer-container .main-box .nav-outer .outer-box .header-flyout-searchbar i {
                        position: relative;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 16px;
                        color: #fff;
                        top: -2px;
                    }

                .main-header .outer-container .main-box .nav-outer .outer-box .btn-box {
                    position: relative;
                    display: inline-block;
                    margin-left: 95px;
                }



/** main-menu **/

.main-menu {
    position: relative;
    display: block;
}

    .main-menu .navbar-collapse {
        padding: 0px;
    }

    .main-menu .navigation > li {
        position: relative;
        float: left;
        margin: 0px 31px;
    }

        .main-menu .navigation > li:first-child {
            margin-left: 0px;
        }

        .main-menu .navigation > li > a {
            position: relative;
            display: block;
            font-size: 17px;
            font-family: 'Montserrat', sans-serif;
            color: #fff;
            line-height: 26px;
            font-weight: 600;
            opacity: 1;
            text-transform: capitalize;
            padding: 57px 0px 34px 0px;
            transition: all 500ms ease;
        }

    .main-menu .navigation {
        position: relative;
    }

        .main-menu .navigation > li > ul {
            position: absolute;
            left: 0px;
            top: 100%;
            width: 250px;
            z-index: 100;
            display: none;
            background: #ffffff;
            border-top: 3px solid #3b04db;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
        }

            .main-menu .navigation > li > ul > li {
                position: relative;
                width: 100%;
                padding: 7px 30px;
                border-bottom: 1px solid #e5e5e5;
                transition: all 500ms ease;
            }

                .main-menu .navigation > li > ul > li:before {
                    position: absolute;
                    content: '';
                    background: #fff;
                    width: 10px;
                    height: 10px;
                    left: 20px;
                    top: 20px;
                    border-radius: 50%;
                    transition: all 500ms ease;
                }

                .main-menu .navigation > li > ul > li:last-child {
                    border-bottom: none;
                }

                .main-menu .navigation > li > ul > li > a {
                    position: relative;
                    display: block;
                    font-family: 'Montserrat', sans-serif;
                    padding: 6px 0px;
                    line-height: 24px;
                    font-size: 15px;
                    color: #222;
                    font-weight: 500;
                    text-align: left;
                    text-transform: capitalize;
                    transition: all 500ms ease;
                }

                .main-menu .navigation > li > ul > li:hover {
                    background: #3b04db;
                    border-bottom: 1px solid #3b04db;
                }

                    .main-menu .navigation > li > ul > li:hover a {
                        color: #ffffff;
                        padding-left: 10px;
                    }

                .main-menu .navigation > li > ul > li:last-child:hover {
                    border-bottom: none;
                }

        .main-menu .navigation > li.dropdown:hover > ul {
            visibility: visible;
            opacity: 1;
        }

    .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
        position: absolute;
        right: -20px;
        top: 57px;
        font-size: 17px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        z-index: 5;
    }

.navbar-header {
    display: none;
}

/** sub-menu **/

.main-menu .navigation > li > ul > li > ul {
    position: absolute;
    left: 100%;
    top: 0%;
    width: 250px;
    z-index: 100;
    display: none;
    padding: 0px 0px;
    background-color: #ffffff;
    border-top: 3px solid #3b04db;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation li > ul > li.dropdown:hover > ul {
    visibility: visible;
    opacity: 1;
    top: 0;
}

.main-menu .navigation > li > ul > li > ul > li {
    position: relative;
    width: 100%;
    margin: 0px;
    padding: 7px 30px;
    border-bottom: 1px solid #e5e5e5;
    transition: all 500ms ease;
}

    .main-menu .navigation > li > ul > li > ul > li:last-child {
        border-bottom: none;
    }

    .main-menu .navigation > li > ul > li > ul > li > a {
        position: relative;
        display: block;
        padding: 6px 0px;
        line-height: 24px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        text-transform: capitalize;
        color: #222 !important;
        transition: all 500ms ease;
    }

    .main-menu .navigation > li > ul > li > ul > li:hover {
        color: #ffffff !important;
        background: #3b04db;
        padding-left: 25px;
    }

        .main-menu .navigation > li > ul > li > ul > li:hover > a {
            color: #ffffff !important;
            padding-left: 25px;
        }

    .main-menu .navigation > li > ul > li > ul > li:before {
        position: absolute;
        content: '';
        background: #fff;
        width: 10px;
        height: 10px;
        left: 20px;
        top: 20px;
        border-radius: 50%;
        transition: all 500ms ease;
    }

.main-menu .navigation > li > ul > li.dropdown .dropdown-btn {
    display: none;
}

.main-menu .navigation > li > ul > li.dropdown > a:after {
    position: absolute;
    content: "\f105";
    font-size: 16px;
    color: #222;
    top: 6px;
    right: -12px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    cursor: pointer;
    transition: all 500ms ease;
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after {
    color: #ffffff;
}


/** stricky-header **/

.sticky-header .main-menu .navigation > li {
    margin: 0px;
}

    .sticky-header .main-menu .navigation > li > a {
        padding: 22px 30px !important;
    }

    .sticky-header .main-menu .navigation > li > a {
        color: #222222;
    }

    .sticky-header .main-menu .navigation > li.current > a,
    .sticky-header .main-menu .navigation > li:hover > a {
        background: #8bca24;
        color: #ffffff !important;
    }

.sticky-header .logo-box {
    position: relative;
    float: left;
    padding: 5px 0px;
}

    .sticky-header .logo-box img {
        height: 20px;
        margin: 20px 0;
    }

.sticky-header .menu-area {
    position: relative;
    float: right;
}

.sticky-header .main-menu .navigation > li > a:before {
    display: none;
}

.sticky-header .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
    display: none;
}

/** search-box **/

.wraper_flyout_search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

    .wraper_flyout_search > .table {
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 100%;
        margin: 0;
    }

        .wraper_flyout_search > .table > .table-cell {
            position: relative;
            display: table-cell;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            text-align: center;
        }

            .wraper_flyout_search > .table > .table-cell > .flyout-search-layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                transform: translateX(100%);
                -webkit-transform: translateX(100%);
            }

                .wraper_flyout_search > .table > .table-cell > .flyout-search-layer:nth-child(1) {
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    transition: all 0.3s ease-in-out 0s;
                    -webkit-transition: all 0.3s ease-in-out 0s;
                }

                .wraper_flyout_search > .table > .table-cell > .flyout-search-layer:nth-child(2) {
                    left: 0px;
                    background-color: rgba(0, 0, 0, 0.4);
                    transition: all 0.3s ease-in-out 0.3s;
                    -webkit-transition: all 0.3s ease-in-out 0.3s;
                }

                .wraper_flyout_search > .table > .table-cell > .flyout-search-layer:nth-child(3) {
                    left: 0px;
                    background-color: rgba(0, 0, 0, 0.9);
                    transition: all 0.9s ease-in-out 0.6s;
                    -webkit-transition: all 0.9s ease-in-out 0.6s;
                }

            .wraper_flyout_search > .table > .table-cell > .flyout-search-close {
                position: absolute;
                top: 40px;
                right: 40px;
                width: 30px;
                z-index: 2;
                text-align: center;
                cursor: pointer;
                transition: all 500ms ease;
            }

                .wraper_flyout_search > .table > .table-cell > .flyout-search-close:hover {
                    transform: rotate(360deg);
                }

                .wraper_flyout_search > .table > .table-cell > .flyout-search-close > .flyout-search-close-line {
                    width: 100%;
                    height: 2px;
                    float: left;
                    margin-bottom: 5px;
                    background-color: #fff;
                }

                    .wraper_flyout_search > .table > .table-cell > .flyout-search-close > .flyout-search-close-line:nth-child(1) {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }

                    .wraper_flyout_search > .table > .table-cell > .flyout-search-close > .flyout-search-close-line:nth-child(2) {
                        margin-top: -7px;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }

            .wraper_flyout_search > .table > .table-cell .flyout_search {
                position: relative;
                z-index: 1;
                float: right;
                width: 100%;
                max-width: 500px;
                padding: 100px 40px 100px 40px;
                text-align: right;
            }

                .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-title {
                    margin-bottom: 30px;
                }

                    .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-title > *:last-child {
                        margin-bottom: 0 !important;
                    }

                    .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-title h4 {
                        margin-bottom: 20px;
                        text-transform: uppercase;
                        font-weight: 300;
                        font-size: 35px;
                        color: #fff;
                        line-height: 45px;
                        letter-spacing: 50px;
                        opacity: 0;
                        transition: all 1s ease-in-out 0s;
                        -webkit-transition: all 1s ease-in-out 0s;
                    }

                .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-bar .form-row {
                    position: relative;
                    padding: 0;
                    margin: 0;
                }

                    .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-bar .form-row input[type=search] {
                        width: 100%;
                        height: 60px;
                        padding: 0px 40px 0px 0px;
                        background-color: initial;
                        border: none;
                        border-bottom: 3px #dedede solid;
                        box-shadow: initial;
                        font-size: 20px;
                        color: #fff;
                    }

                    .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-bar .form-row button[type=submit] {
                        position: absolute;
                        top: 17px;
                        right: 0;
                        z-index: 1;
                        width: 40px;
                        height: auto;
                        background-color: initial;
                        border: none;
                        cursor: pointer;
                    }

                        .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-bar .form-row button[type=submit] i {
                            font-size: 20px;
                            color: #fff;
                            line-height: 20px;
                        }

body.flyout-searchbar-active .wraper_flyout_search {
    opacity: 1;
    visibility: visible;
}

    body.flyout-searchbar-active .wraper_flyout_search > .table > .table-cell > .flyout-search-layer {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    body.flyout-searchbar-active .wraper_flyout_search > .table > .table-cell .flyout_search > .flyout-search-title h4 {
        letter-spacing: 0;
        opacity: 1;
    }

#hamburger-menu {
    width: 100%;
}


/** banner-section **/

.banner-section {
    position: relative;
    width: 100%;
    padding: 248px 0px 270px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

    .banner-section .content-box {
        position: relative;
        display: block;
        margin-right: -15px;
    }

        .banner-section .content-box:before {
            position: absolute;
            content: '';
            background: url(../dist/images/icons/shap-1.png);
            width: 288px;
            height: 280px;
            left: -130px;
            top: -100px;
            background-repeat: no-repeat;
        }

        .banner-section .content-box:after {
            position: absolute;
            content: '';
            background: url(../dist/images/icons/shap-2.png);
            width: 150px;
            height: 137px;
            left: -60px;
            top: -30px;
            background-repeat: no-repeat;
            animation: zoom-fade-two 5s infinite linear;
        }

        .banner-section .content-box h1 {
            position: relative;
            display: block;
            font-size: 65px;
            line-height: 65px;
            font-weight: 700;
            color: #fff;
            margin-bottom: 43px;
        }

        .banner-section .content-box .text {
            position: relative;
            display: block;
            font-size: 17px;
            line-height: 28px;
            font-weight: 400;
            margin-bottom: 54px;
        }

    .banner-section .image-box {
        position: relative;
        padding-left: 50px;
    }

        .banner-section .image-box img {
            position: relative;
            max-width: none;
            float: left;
        }

.float-bob-y {
    animation-name: float-bob-y;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.float-bob-x {
    animation-name: float-bob-x;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.anim-icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

    .anim-icon .icon {
        position: absolute;
        background-repeat: no-repeat;
    }

.banner-section .anim-icon .icon-1 {
    background: url(../dist/images/icons/anim-icon-1.png);
    width: 186px;
    height: 81px;
    left: 30%;
    top: 0px;
}

.banner-section .anim-icon .icon-2 {
    background: url(../dist/images/icons/anim-icon-2.png);
    width: 86px;
    height: 82px;
    right: 80px;
    top: 70px;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
}

.banner-section .anim-icon .icon-3 {
    background: url(../dist/images/icons/anim-icon-3.png);
    width: 137px;
    height: 132px;
    right: 80px;
    bottom: 170px;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
}

.banner-section .anim-icon .icon-4 {
    background: url(../dist/images/icons/anim-icon-4.png);
    width: 170px;
    height: 165px;
    left: 80px;
    bottom: 170px;
}


/** feature-section **/

.feature-section {
    position: relative;
    display: block;
    background: #fff;
    padding: 40px 0px 175px 0px;
}

    .feature-section:before {
        position: absolute;
        content: '';
        background: url(../dist/images/background/feature-bg.png);
        width: 100%;
        height: 1652px;
        left: 0px;
        top: 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

.feature-block {
    margin-bottom: 30px;
}

.feature-block-one {
    position: relative;
    display: block;
    z-index: 1;
}

    .feature-block-one .inner-box {
        position: relative;
        display: block;
        padding: 70px 30px;
        background: transparent;
        transition: all 500ms ease;
    }

        .feature-block-one .inner-box:hover {
            background: white;
            box-shadow: 0 23px 50px rgba(176, 176, 176, .35);
        }

        .feature-block-one .inner-box .icon-box {
            position: relative;
            display: block;
            min-height: 130px;
            margin-bottom: 37px;
        }

            .feature-block-one .inner-box .icon-box img {
                position: relative;
                display: inline-block;
                width: auto;
                transition: all 500ms ease;
            }

    .feature-block-one:hover .inner-box .icon-box img {
        transform: rotateY(180deg);
    }

    .feature-block-one .inner-box h2 {
        position: relative;
        display: block;
        font-size: 30px;
        line-height: 40px;
        color: #09092b;
        font-weight: 600;
        margin-bottom: 37px;
    }

        .feature-block-one .inner-box h2 a {
            position: relative;
            display: inline-block;
            color: #09092b;
        }

            .feature-block-one .inner-box h2 a:hover {
                color: #8bca24;
            }

    .feature-block-one .inner-box .text {
        position: relative;
        display: block;
        font-size: 15px;
        line-height: 26px;
    }


/** about-section **/

.about-section {
    position: relative;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

    .about-section .content-box .sec-title {
        position: relative;
        display: block;
        margin-bottom: 22px;
    }

    .about-section .content-box .text {
        position: relative;
        display: block;
        margin-bottom: 27px;
        text-align: justify;
    }

    .about-section .content-box .list {
        position: relative;
        display: block;
        margin-bottom: 42px;
    }

        .about-section .content-box .list li {
            position: relative;
            display: block;
            font-size: 16px;
            margin-bottom: 6px;
            padding: 0px 0px 0px 34px;
        }

            .about-section .content-box .list li:last-child {
                margin-bottom: 0px;
            }

            .about-section .content-box .list li:before {
                position: absolute;
                content: "\f101";
                font-family: 'Flaticon';
                font-size: 20px;
                color: #8bca24;
                font-weight: 500;
                left: 0px;
                top: 0px;
            }

    .about-section .image-box {
        position: relative;
        display: block;
        margin-left: 300px;
        margin-top: -62px;
    }

        .about-section .image-box:before {
            position: absolute;
            content: '';
            background: url(../dist/images/icons/leaf.png);
            width: 348px;
            height: 360px;
            left: -185px;
            bottom: -54px;
        }

        .about-section .image-box img {
            position: relative;
            max-width: none;
            float: left;
            box-shadow: 0 23px 50px rgba(176, 176, 176, .35);
        }


/** service-section **/

.service-section {
    position: relative;
    display: block;
    padding: 220px 0px 250px 0px;
    background: #3b04db;
    clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 90%, 0% 0%);
}

    .service-section:after {
        position: absolute;
        content: '';
        background: url(../dist/images/icons/icon-9.png);
        width: 396px;
        height: 245px;
        left: 100px;
        top: 0px;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .service-section .anim-icon .icon-1 {
        background: url(../dist/images/icons/anim-icon-8.png);
        width: 19px;
        height: 32px;
        left: 80px;
        top: 350px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-2 {
        background: url(../dist/images/icons/anim-icon-9.png);
        width: 28px;
        height: 28px;
        left: 30%;
        top: 160px;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .service-section .anim-icon .icon-3 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        left: 25%;
        top: 400px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-4 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        left: 40%;
        top: 200px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-5 {
        background: url(../dist/images/icons/anim-icon-11.png);
        width: 16px;
        height: 16px;
        right: 19%;
        top: 350px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-6 {
        background: url(../dist/images/icons/anim-icon-12.png);
        width: 28px;
        height: 28px;
        right: 15%;
        top: 450px;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .service-section .anim-icon .icon-7 {
        background: url(../dist/images/icons/anim-icon-8.png);
        width: 19px;
        height: 32px;
        right: 17%;
        top: 57%;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-8 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        right: 10%;
        bottom: 200px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-9 {
        background: url(../dist/images/icons/anim-icon-9.png);
        width: 28px;
        height: 28px;
        right: 42%;
        bottom: 200px;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .service-section .anim-icon .icon-10 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        left: 48%;
        bottom: 250px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-11 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        left: 70px;
        bottom: 300px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-12 {
        background: url(../dist/images/icons/anim-icon-13.png);
        width: 19px;
        height: 32px;
        left: 320px;
        bottom: 380px;
        background-repeat: no-repeat;
    }

    .service-section .anim-icon .icon-13 {
        background: url(../dist/images/icons/anim-icon-12.png);
        width: 28px;
        height: 28px;
        left: 260px;
        top: 50%;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .service-section .sec-title h4 {
        color: #b19bf1;
    }

    .service-section .sec-title h1 {
        color: #fff;
    }

    .service-section .sec-title {
        position: relative;
        display: block;
        margin-bottom: 60px;
    }

.service-block-one {
    position: relative;
    display: block;
    background: #5424e0;
    padding: 82px 30px 70px 65px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: all 500ms ease;
}

    .service-block-one:before {
        position: absolute;
        content: '';
        background: url(../dist/images/icons/icon-8.png);
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        right: 0px;
        border-radius: 10px;
        transform: scale(0, 0);
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 500ms ease;
    }

    .service-block-one:hover:before {
        transform: scale(1, 1);
    }

    .service-block-one .inner-box {
        position: relative;
        display: block;
        padding-left: 97px;
    }

        .service-block-one .inner-box .icon-box {
            position: absolute;
            left: 0px;
            top: -7px;
        }

            .service-block-one .inner-box .icon-box:before {
                position: absolute;
                content: '';
                background: #663be3;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                left: -25px;
                bottom: -18px;
                transition: all 900ms ease;
            }

    .service-block-one:hover .inner-box .icon-box:before {
        background: #e8e8e8;
    }

    .service-block-one .inner-box .icon-box img {
        position: relative;
        width: auto;
        transition: all 500ms ease;
    }

    .service-block-one:hover .inner-box .icon-box img {
        -webkit-filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(1);
        filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(1);
    }

    .service-block-one .inner-box h3 {
        position: relative;
        display: block;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 15px;
        transition: all 500ms ease;
    }

    .service-block-one:hover .inner-box h3,
    .service-block-one:hover .inner-box h3 a {
        color: #09092b;
    }

    .service-block-one .inner-box h3 a {
        position: relative;
        display: inline-block;
        color: #fff;
    }

        .service-block-one .inner-box h3 a:hover,
        .service-block-one .inner-box .link-box a:hover {
            color: #8bca24;
        }

    .service-block-one .inner-box .text {
        position: relative;
        display: block;
        color: #b0a5f3;
        margin-bottom: 16px;
        transition: all 500ms ease;
    }

    .service-block-one:hover .inner-box .text {
        color: #848484;
    }

    .service-block-one .inner-box .link-box a {
        position: relative;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #b0a5f3;
        font-family: 'Montserrat', sans-serif;
    }

    .service-block-one:hover .inner-box .link-box a {
        color: #8bca24;
    }


/** subscribe-section **/

.subscribe-section {
    position: relative;
    display: block;
    width: 100%;
    padding: 143px 0px 365px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

    .subscribe-section .sec-title h1 {
        color: #fff;
        margin-bottom: 50px;
    }

    .subscribe-section .inner-content {
        position: relative;
        display: block;
        max-width: 830px;
        width: 100%;
        margin: 0 auto;
    }

        .subscribe-section .inner-content .form-group {
            position: relative;
            display: inline-block;
            margin: 0px;
            max-width: 305px;
            width: 100%;
            float: left;
        }

            .subscribe-section .inner-content .form-group input[type='text'],
            .subscribe-section .inner-content .form-group input[type='email'] {
                position: relative;
                display: block;
                width: 100%;
                height: 60px;
                font-size: 14px;
                font-family: 'Open Sans', sans-serif;
                padding: 10px 30px;
                border-right: 1px solid #e5e5e5;
            }

            .subscribe-section .inner-content .form-group:first-child input {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            .subscribe-section .inner-content .form-group:nth-child(2) input {
                border-right: none;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            .subscribe-section .inner-content .form-group:last-child {
                position: absolute;
                right: 0px;
                top: 0px;
                max-width: 200px;
                width: 100%;
            }

            .subscribe-section .inner-content .form-group .btn-style-two {
                width: 100%;
                padding: 17px 30px 17px 31px;
            }

    .subscribe-section .anim-icon .icon-1 {
        background: url(../dist/images/icons/anim-icon-5.png);
        width: 100px;
        height: 175px;
        right: 0px;
        bottom: 120px;
    }

    .subscribe-section .anim-icon .icon-2 {
        background: url(../dist/images/icons/anim-icon-4.png);
        width: 170px;
        height: 165px;
        left: 100px;
        bottom: 10px;
    }

    .subscribe-section .anim-icon .icon-3 {
        background: url(../dist/images/icons/anim-icon-14.png);
        width: 30px;
        height: 21px;
        left: 130px;
        top: 160px;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-4 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        left: 130px;
        top: 50%;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-5 {
        background: url(../dist/images/icons/anim-icon-12.png);
        width: 28px;
        height: 28px;
        left: 19%;
        top: 65%;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .subscribe-section .anim-icon .icon-6 {
        background: url(../dist/images/icons/anim-icon-13.png);
        width: 19px;
        height: 32px;
        top: 30%;
        left: 20%;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-7 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        top: 180px;
        left: 30%;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-8 {
        background: url(../dist/images/icons/anim-icon-12.png);
        width: 28px;
        height: 28px;
        top: 80px;
        left: 38%;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .subscribe-section .anim-icon .icon-9 {
        background: url(../dist/images/icons/anim-icon-11.png);
        width: 16px;
        height: 16px;
        left: 62%;
        top: 70px;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-10 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        top: 190px;
        right: 20%;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-11 {
        background: url(../dist/images/icons/anim-icon-9.png);
        width: 28px;
        height: 28px;
        right: 100px;
        top: 40%;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .subscribe-section .anim-icon .icon-12 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        right: 140px;
        bottom: 30%;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-13 {
        background: url(../dist/images/icons/anim-icon-8.png);
        width: 19px;
        height: 32px;
        right: 350px;
        bottom: 150px;
        background-repeat: no-repeat;
    }

    .subscribe-section .anim-icon .icon-14 {
        background: url(../dist/images/icons/anim-icon-9.png);
        width: 28px;
        height: 28px;
        right: 38%;
        bottom: 200px;
        background-repeat: no-repeat;
        -webkit-animation: zoom-fade 5s infinite linear;
        animation: zoom-fade 5s infinite linear;
    }

    .subscribe-section .anim-icon .icon-15 {
        background: url(../dist/images/icons/anim-icon-10.png);
        width: 16px;
        height: 16px;
        right: 48%;
        bottom: 300px;
        background-repeat: no-repeat;
    }


/** main-footer **/

.main-footer {
    position: relative;
    display: block;
    padding-top: 100px;
}

    .main-footer .widget-content {
        position: relative;
        display: block;
        padding-bottom: 80px;
        border-bottom: 1px solid #f0f0f0;
    }

@media only screen and (max-width: 767px) {
    .main-footer .widget-content {
        padding-bottom: 30px;
    }
}

.main-footer:before {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/shap-7.png);
    width: 620px;
    height: 350px;
    top: 50px;
    right: 0px;
    background-repeat: no-repeat;
}

.main-footer:after {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/shap-8.png);
    width: 350px;
    height: 260px;
    left: 0px;
    bottom: 80px;
    background-repeat: no-repeat;
    z-index: -1;
}

.main-footer .widget-content .logo-widget {
    display: block;
    margin-right: 30px;
}

    .main-footer .widget-content .logo-widget .footer-logo {
        display: block;
        margin-bottom: 15px;
    }

        .main-footer .widget-content .logo-widget .footer-logo img {
            height: 20px;
        }

    .main-footer .widget-content .logo-widget .text {
        display: block;
        margin-bottom: 27px;
    }

    .main-footer .widget-content .logo-widget .phone i {
        position: relative;
        display: inline-block;
        font-size: 25px;
        line-height: 35px;
        color: #c8c9cc;
        margin-right: 20px;
    }

    .main-footer .widget-content .logo-widget .phone a {
        position: relative;
        display: inline-block;
        font-size: 28px;
        line-height: 35px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        color: #8bca24;
    }

.main-footer .widget-title {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #3b3b3b;
    margin-bottom: 36px;
}

.main-footer .link-widget .link-list li {
    position: relative;
    display: block;
    margin-bottom: 12px;
}

.main-footer .link-widget {
    margin-left: 15px;
}

    .main-footer .link-widget .link-list li:last-child {
        margin-bottom: 0px;
    }

    .main-footer .link-widget .link-list li a {
        position: relative;
        display: inline-block;
        font-size: 16px;
        line-height: 26px;
        font-family: 'Open Sans', sans-serif;
        color: #848484;
    }

.main-footer .footer-widget {
    position: relative;
    display: block;
}

.main-footer .link-widget .link-list li a:hover {
    color: #8bca24;
}

.main-footer .service-widget {
    margin-left: 70px;
}

.main-footer .contact-widget .info-box li {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
}

    .main-footer .contact-widget .info-box li:last-child {
        margin-bottom: 0px;
    }

    .main-footer .contact-widget .info-box li a {
        position: relative;
        display: inline-block;
        font-family: 'Open Sans', sans-serif;
        color: #848484;
    }

        .main-footer .contact-widget .info-box li a:hover {
            color: #8bca24;
        }

.main-footer .contact-widget .info-box {
    position: relative;
    display: block;
    margin-bottom: 26px;
}

.main-footer .contact-widget .footer-social li {
    position: relative;
    display: inline-block;
    margin-right: 8px;
}

    .main-footer .contact-widget .footer-social li:last-child {
        margin-right: 0px;
    }

    .main-footer .contact-widget .footer-social li a {
        position: relative;
        display: inline-block;
        font-size: 14px;
        color: #848484;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 50%;
        border: 2px solid #f0f0f0;
    }

        .main-footer .contact-widget .footer-social li a:hover {
            background: #8bca24;
            border-color: #8bca24;
            color: #fff;
        }

.main-footer .footer-bottom {
    position: relative;
    display: block;
    padding: 23px 0px 25px 0px;
}

    .main-footer .footer-bottom .copyright {
        position: relative;
        display: inline-block;
        font-size: 15px;
        color: #222;
    }

        .main-footer .footer-bottom .copyright a {
            position: relative;
            display: inline-block;
            color: #8bca24;
        }

            .main-footer .footer-bottom .copyright a:hover {
                text-decoration: underline;
            }

    .main-footer .footer-bottom .right-content ul li {
        position: relative;
        display: inline-block;
        margin-left: 12px;
        padding-left: 17px;
    }

        .main-footer .footer-bottom .right-content ul li:before {
            position: absolute;
            content: '';
            background: #222;
            width: 1px;
            height: 15px;
            left: 0px;
            top: 8px;
        }

        .main-footer .footer-bottom .right-content ul li:first-child:before {
            display: none;
        }

        .main-footer .footer-bottom .right-content ul li:first-child {
            padding: 0px;
            margin: 0px;
        }

        .main-footer .footer-bottom .right-content ul li a {
            position: relative;
            display: inline-block;
            font-size: 15px;
            color: #222;
        }

            .main-footer .footer-bottom .right-content ul li a:hover {
                color: #8bca24;
            }

/*** 

====================================================================
                        Home-Page-Two
====================================================================

***/


/** header-style-two **/

.main-header.style-two {
    position: relative;
}

    .main-header.style-two .main-menu .navigation > li > a,
    .main-header.style-two .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn,
    .main-header.style-two .outer-container .main-box .nav-outer .outer-box .header-flyout-searchbar i {
        color: #3b3b3b;
    }


/** banner-style-two **/


.banner-section.style-two {
    position: relative;
    background: #fff;
    padding: 333px 0px 165px 0px;
}

    .banner-section.style-two:before {
        position: absolute;
        content: '';
        background: url(../dist/images/background/banner-shap.png);
        width: 1316px;
        height: 983px;
        top: 0px;
        right: 0px;
        background-repeat: no-repeat;
    }

    .banner-section.style-two .content-box:before {
        background: url(../dist/images/icons/shap-9.png);
        width: 288px;
        height: 267px;
    }

    .banner-section.style-two .content-box h1 {
        color: #3b3b3b;
        margin-bottom: 28px;
        line-height: 75px;
    }

    .banner-section.style-two .content-box {
        position: relative;
        z-index: 1;
    }

.banner-section .anim-icon .icon-5 {
    background: url(../dist/images/icons/shap-11.png);
    height: 409px;
    left: -225px;
    top: 240px;
    width: 408px;
    -webkit-animation: zoom-fade 5s infinite linear;
    animation: zoom-fade 5s infinite linear;
}



/** clients-section **/

.clients-section {
    position: relative;
    display: block;
    padding: 100px 0px 90px 0px;
}

    .clients-section:before {
        position: absolute;
        content: '';
        background: url(../dist/images/background/about-shap.png);
        width: 861px;
        height: 1107px;
        left: 0px;
        top: -100px;
        background-repeat: no-repeat;
    }

    .clients-section .clients-outer {
        position: relative;
    }

    .clients-section .image-box {
        position: relative;
        display: block;
        text-align: center;
        margin-bottom: 0;
    }

        .clients-section .image-box img {
            display: block;
            max-width: 100%;
            width: auto;
            margin: 0 auto;
            opacity: 1;
            -webkit-transition: all 300ms ease;
            -moz-transition: all 300ms ease;
            -ms-transition: all 300ms ease;
            -o-transition: all 300ms ease;
            transition: all 300ms ease;
        }

    .clients-section .slide-item:hover .image-box img {
        opacity: 0.5;
    }

    .clients-section .owl-nav.disabled {
        display: none;
    }


/** about-style-two **/

.about-section.style-two {
    position: relative;
}

@media only screen and (max-width: 767px) {
    .about-section.style-two {
        padding: 0;
    }
}

.about-section.style-two:after {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/shap-13.png);
    width: 265px;
    height: 325px;
    right: 0px;
    top: 0px;
    background-repeat: no-repeat;
    z-index: -1;
}

.about-section.style-two .image {
    border-radius: 10px;
    display: inline-block;
    float: left;
    padding-left: 30px;
    padding-top: 110px;
    position: relative;
    width: 90%;
}

@media only screen and (max-width: 599px) {
    .about-section.style-two .image {
        padding-top: 0;
        width: 100%;
    }
}

.about-section.style-two .image:before {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/shap-12.png);
    width: 131px;
    height: 253px;
    right: -70px;
    bottom: 60px;
    background-repeat: no-repeat;
}

.about-section.style-two .image img {
    position: relative;
    width: 100%;
    border-radius: 10px;
}

.about-section.style-two .video-content {
    position: relative;
    display: block;
}

    .about-section.style-two .video-content .video-icon {
        position: absolute;
        left: -20px;
        top: 100px;
    }

        .about-section.style-two .video-content .video-icon a {
            position: relative;
            display: inline-block;
            font-size: 20px;
            color: #fff;
            height: 100px;
            width: 100px;
            line-height: 100px;
            text-align: center;
            background: #8bca24;
            border-radius: 50%;
            transition: all 500ms ease;
            animation: pulse 3s infinite;
        }

.about-section.style-two .content-box {
    margin-left: 35px;
    margin-top: 30px;
}

/** feature-style-two **/

.feature-section.style-two {
    position: relative;
    padding: 0 0 90px 0;
}

    .feature-section.style-two:before {
        background: url(../dist/images/background/feature-patten.png);
        width: 100%;
        height: 1488px;
        top: 100px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .feature-section.style-two .sec-title {
        margin-bottom: 108px;
    }

@media only screen and (max-width: 767px) {
    .feature-section.style-two .sec-title {
        margin-bottom: 20px;
    }
}

.feature-section.style-two .inner-box .btn-box {
    transform: scale(0, 0);
    transition: all 500ms ease;
}

.feature-section.style-two .inner-box:hover .btn-box {
    transform: scale(1, 1);
}

/** portfolio-section **/


.portfolio-section {
    position: relative;
    display: none;
    padding: 150px 0px 120px 0px;
}

    .portfolio-section .tabs-content {
        position: relative;
        display: block;
        margin-right: -560px;
        padding-bottom: 70px;
        border-bottom: 1px solid #e5e5e5;
    }

        .portfolio-section .tabs-content:before {
            position: absolute;
            content: '';
            background: #8bca24;
            width: 25%;
            height: 2px;
            left: 0px;
            bottom: -1px;
        }

    .portfolio-section .top-content {
        position: relative;
        display: block;
        margin-bottom: 60px;
    }

        .portfolio-section .top-content .tab-btns li {
            position: relative;
            float: left;
            margin-right: 25px;
            font-size: 16px;
            font-weight: 500;
            font-family: 'Montserrat', sans-serif;
            color: #848484;
            cursor: pointer;
            transition: all 500ms ease;
        }

            .portfolio-section .top-content .tab-btns li.active-btn,
            .portfolio-section .top-content .tab-btns li:hover {
                color: #8bca24;
            }

        .portfolio-section .top-content .tab-btn-box {
            position: relative;
            margin-top: 73px;
        }

        .portfolio-section .top-content .tab-btns li:last-child {
            margin-right: 0px;
        }

.portfolio-block-one {
    position: relative;
    display: block;
}

    .portfolio-block-one .inner-box {
        position: relative;
        display: block;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 23px 50px rgba(176, 176, 176, .35);
    }

        .portfolio-block-one .inner-box .image-box {
            position: relative;
            display: block;
            overflow: hidden;
            background: #000;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }

            .portfolio-block-one .inner-box .image-box img {
                width: 100%;
                transition: all 1500ms ease;
            }

        .portfolio-block-one .inner-box:hover .image-box img {
            opacity: 0.5;
            transform: scale(1.3) rotate(3deg);
        }

        .portfolio-block-one .inner-box .lower-content {
            position: relative;
            display: block;
            padding: 32px 150px 28px 40px;
        }

            .portfolio-block-one .inner-box .lower-content h3 {
                position: relative;
                display: block;
                font-size: 24px;
                line-height: 30px;
                font-weight: 600;
                color: #3b3b3b;
                margin-bottom: 0px;
            }

                .portfolio-block-one .inner-box .lower-content h3 a {
                    position: relative;
                    display: inline-block;
                    color: #3b3b3b;
                }

                    .portfolio-block-one .inner-box .lower-content h3 a:hover {
                        color: #8bca24;
                    }

            .portfolio-block-one .inner-box .lower-content span {
                position: absolute;
                right: 42px;
                top: 37px;
                font-size: 15px;
                font-family: 'Montserrat', sans-serif;
                font-weight: 500;
                color: #848484;
            }


/** testimonial-style-two **/

.testimonial-style-two {
    position: relative;
    display: none;
    padding: 160px 0px 100px 0px;
}

    .testimonial-style-two:before {
        position: absolute;
        content: '';
        background: url(../dist/images/background/testimonial-patten.png);
        width: 714px;
        height: 919px;
        left: 0px;
        top: -150px;
        background-repeat: no-repeat;
    }

    .testimonial-style-two:after {
        position: absolute;
        content: '';
        background: url(../dist/images/icons/leaf-3.png);
        width: 268px;
        height: 434px;
        right: 0px;
        bottom: 0px;
        background-repeat: no-repeat;
    }

    .testimonial-style-two .testimonial-carousel {
        position: relative;
    }

        .testimonial-style-two .testimonial-carousel:before {
            position: absolute;
            content: '';
            background: url(../dist/images/icons/shap-16.png);
            width: 805px;
            height: 361px;
            left: 110px;
            top: -140px;
            background-repeat: no-repeat;
        }

    .testimonial-style-two .inner-box .image-box .image {
        position: relative;
        display: inline-block;
        float: left;
        border-radius: 10px;
    }

        .testimonial-style-two .inner-box .image-box .image:before {
            position: absolute;
            content: "\f10e";
            font-family: 'Font Awesome 5 Free';
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            background: #8bca24;
            width: 80px;
            height: 80px;
            right: -40px;
            bottom: 87px;
            line-height: 80px;
            text-align: center;
            border-radius: 50%;
            z-index: 1;
        }

        .testimonial-style-two .inner-box .image-box .image img {
            width: 100%;
            border-radius: 10px;
        }

    .testimonial-style-two .inner-box .content-box {
        position: relative;
        display: block;
        margin-top: 39px;
    }

        .testimonial-style-two .inner-box .content-box .sec-title {
            margin-bottom: 48px;
        }

        .testimonial-style-two .inner-box .content-box .text {
            position: relative;
            display: block;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            color: #3b3b3b;
            line-height: 32px;
            margin-bottom: 47px;
        }

        .testimonial-style-two .inner-box .content-box .author-info h4 {
            position: relative;
            display: block;
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            color: #3b3b3b;
            margin-bottom: 4px;
        }

        .testimonial-style-two .inner-box .content-box .author-info span {
            position: relative;
            display: block;
            font-size: 15px;
            line-height: 24px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: #8b8e93;
        }

        .testimonial-style-two .inner-box .content-box .sec-title h4 {
            margin-bottom: 13px;
        }

    .testimonial-style-two .owl-nav {
        position: relative;
        text-align: center;
        top: -96px;
        left: 58px;
    }

    .testimonial-style-two .nav-style-one .owl-nav .owl-prev:before,
    .testimonial-style-two .nav-style-one .owl-nav .owl-next:before {
        top: 19.5px;
    }

/** pricing-style-two **/

.pricing-style-two {
    position: relative;
    display: block;
    padding: 85px 0px 200px 0px;
    background-size: cover;
    background-repeat: no-repeat;
}

    .pricing-style-two:after {
        position: absolute;
        content: '';
        background: url(../dist/images/background/price-patten.png);
        width: 100%;
        height: 977px;
        right: 0px;
        bottom: 0px;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    .pricing-style-two .sec-title {
        position: relative;
        display: block;
        margin-bottom: 57px;
    }

    .pricing-style-two .pricing-table {
        position: relative;
        display: block;
        background: #fff;
        padding: 63px 60px 55px 60px;
        box-shadow: 0 23px 50px rgba(176, 176, 176, .35);
        border-radius: 15px;
        min-height: 631px;
    }

        .pricing-style-two .pricing-table:before {
            position: absolute;
            content: '';
            background: #3b04db;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            right: 0px;
            transform: scale(0, 0);
            border-radius: 15px;
            transition: all 500ms ease;
        }

        .pricing-style-two .pricing-table:hover:before {
            transform: scale(1, 1);
        }

        .pricing-style-two .pricing-table .table-header h1 {
            position: relative;
            display: block;
            font-size: 48px;
            line-height: 60px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            color: #3b3b3b;
            margin-bottom: 17px;
            transition: all 500ms ease;
        }

        .pricing-style-two .pricing-table:hover .table-header h1 {
            color: #fff;
        }

        .pricing-style-two .pricing-table .table-header a {
            position: relative;
            display: inline-block;
            font-size: 17px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            background: transparent;
            border: 2px solid #8bca24;
            color: #3b3b3b;
            text-align: center;
            width: 157px;
            height: 55px;
            line-height: 55px;
            border-radius: 3px;
            margin-bottom: 19px;
        }

        .pricing-style-two .pricing-table:hover .table-header a {
            color: #fff;
            background: #8bca24;
        }

        .pricing-style-two .pricing-table .table-header h4 {
            position: relative;
            display: block;
            font-size: 20px;
            line-height: 28px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            color: #848484;
            transition: all 500ms ease;
        }

        .pricing-style-two .pricing-table:hover .table-header h4 {
            color: #fff;
        }

        .pricing-style-two .pricing-table .table-header {
            position: relative;
            display: block;
            padding-bottom: 47px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 36px;
            transition: all 500ms ease;
        }

        .pricing-style-two .pricing-table:hover .table-header {
            border-color: #4f65c5;
        }

        .pricing-style-two .pricing-table .table-content li {
            position: relative;
            display: block;
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            color: #848484;
            padding: 10px 0px;
            margin-bottom: 5px;
            transition: all 500ms ease;
        }

        .pricing-style-two .pricing-table:hover .table-content li {
            color: #fff;
        }

        .pricing-style-two .pricing-table .table-content li:last-child {
            margin-bottom: 0px;
        }

    .pricing-style-two .inner-content {
        position: relative;
    }

        .pricing-style-two .inner-content:before {
            position: absolute;
            content: '';
            background: url(../dist/images/icons/leaf.png);
            width: 348px;
            height: 360px;
            left: -160px;
            bottom: -47px;
            background-repeat: no-repeat;
        }

/** fact-counter **/

.fact-counter {
    position: relative;
    display: block;
    background: #001433;
    padding: 100px 0 130px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(https://livedemo00.template-help.com/wt_prod-11106/dist/images/bg-image-2-1920x1121.jpg); */
}

    .fact-counter h1, .fact-counter h5 {
        color: white;
        margin-bottom: 15px;
    }

    .fact-counter .counter-block {
        margin-top: 30px;
        color: white;
        border-right: 1px solid rgba(255, 255, 255, 0.58);
    }

        .fact-counter .counter-block:last-child {
            border: none;
        }

@media (min-width: 768px) and (max-width: 992px) {
    .fact-counter .counter-block:nth-child(2n) {
        border: none;
    }
}

@media (max-width: 766px) {
    .fact-counter .counter-block {
        border: none;
    }
}


.fact-counter:before {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/shap-17.png);
    width: 194px;
    height: 250px;
    left: 0px;
    bottom: 0px;
    background-repeat: no-repeat;
}

.fact-counter:after {
    position: absolute;
    content: '';
    background: url(../dist/images/icons/leaf-3.png);
    width: 297px;
    height: 250px;
    right: -40px;
    bottom: 0;
    background-repeat: no-repeat;
}

.counter-block-one {
    position: relative;
    display: block;
    z-index: 1;
}

    .counter-block-one .count-outer span {
        position: relative;
        display: block;
        font-size: 60px;
        line-height: 65px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: white;
        margin-bottom: 8px;
    }

    .counter-block-one .text {
        position: relative;
        display: block;
        color: #fff;
        font-weight: 600;
    }

.count-extra {
    position: absolute !important;
    left: calc(50% + 50px);
    top: 0;
}


/** news-section **/

.news-section {
    position: relative;
    display: none;
    padding: 192px 0px 178px 0px;
}

    .news-section:after {
        position: absolute;
        content: '';
        background: url(../dist/images/icons/shap-21.png);
        width: 410px;
        height: 410px;
        left: 100px;
        top: -200px;
        background-repeat: no-repeat;
        z-index: -1;
        animation: zoom-fade 5s infinite linear;
    }


    .news-section .top-content {
        position: relative;
        display: block;
        margin-bottom: 57px;
    }

        .news-section .top-content .btn-box {
            position: relative;
            margin-top: 40px;
        }

.news-block-one {
    position: relative;
    display: block;
}

    .news-block-one .image-box {
        position: relative;
        display: block;
        overflow: hidden;
    }

    .news-block-one .lower-content {
        position: relative;
        display: block;
        padding: 33px 10px 20px 0px;
    }

        .news-block-one .lower-content .post-date {
            position: relative;
            display: block;
            margin-bottom: 8px;
        }

        .news-block-one .lower-content h3 {
            position: relative;
            display: block;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            color: #3b3b3b;
            margin-bottom: 21px;
        }

            .news-block-one .lower-content h3 a {
                position: relative;
                display: inline-block;
                color: #3b3b3b;
            }

                .news-block-one .lower-content h3 a:hover {
                    color: #8bca24;
                }

        .news-block-one .lower-content .text {
            position: relative;
            display: block;
            font-size: 15px;
            line-height: 26px;
        }

.overlay-style {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    z-index: 2;
    opacity: 0;
    transition: all 900ms ease;
}

    .overlay-style:before {
        position: absolute;
        content: '';
        top: 0px;
        left: 0px;
        width: 100%;
        height: 50%;
        display: block;
        opacity: 0;
        text-align: center;
        transform: perspective(400px) rotateX(-90deg);
        transform-origin: top;
        transition: all 0.5s;
        background-color: rgba(0, 0, 0, 0.80);
    }

    .overlay-style:after {
        position: absolute;
        content: '';
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 50%;
        display: block;
        opacity: 0;
        text-align: center;
        transform: perspective(400px) rotateX(90deg);
        transform-origin: bottom;
        transition: all 0.5s;
        background-color: rgba(0, 0, 0, 0.80);
    }

.news-block-one:hover .overlay-style {
    opacity: 1;
}

    .news-block-one:hover .overlay-style:before {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
        transition: all 700ms ease 100ms;
    }

    .news-block-one:hover .overlay-style:after {
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
        transition: all 700ms ease 300ms;
    }

.news-block-one img {
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}

.news-block-one:hover img {
    transform: scale(1.2) rotate(2deg);
}

.news-block-one .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translate3d(0px, -100%, 0px);
    transition: all 700ms ease 300ms;
}

.news-block-one:hover .overlay {
    transition: all 700ms ease 500ms;
    transform: translate3d(0px, 0px, 0px);
}

.news-block-one .overlay .box {
    display: table;
    width: 100%;
    height: 100%;
}

    .news-block-one .overlay .box .link-icon {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

        .news-block-one .overlay .box .link-icon a i:before {
            display: inline-block;
            color: #ffffff;
            font-size: 35px;
            line-height: 35px;
            transition: all 500ms ease;
        }

        .news-block-one .overlay .box .link-icon a:hover i:before {
            color: #8bca24;
        }

/** subscribe-style-two **/

.subscribe-style-two {
    position: relative;
    display: block;
    background: #f5f6fa;
    padding: 140px 0px 150px 0px;
}

    .subscribe-style-two h1 {
        margin-bottom: 15px;
    }

    .subscribe-style-two:before {
        position: absolute;
        content: '';
        background: url(../dist/images/icons/shap-23.png);
        width: 391px;
        height: 227px;
        right: 100px;
        top: 0px;
        background-repeat: no-repeat;
        background-position: top center;
    }

    .subscribe-style-two .inner-content {
        position: relative;
        display: block;
        max-width: 830px;
        width: 100%;
        margin: auto;
    }

    .subscribe-style-two .subscribe-form .form-group {
        position: relative;
        max-width: 550px;
        width: 100%;
        margin: 0px;
    }

        .subscribe-style-two .subscribe-form .form-group:last-child {
            position: absolute;
            right: 0px;
            top: 0px;
            max-width: 260px;
            width: 100%;
        }

    .subscribe-style-two .subscribe-form input[type='email'] {
        position: relative;
        display: block;
        width: 100%;
        height: 60px;
        background: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        padding: 10px 20px;
        font-size: 14px;
        transition: all 500ms ease;
    }

    .subscribe-style-two .subscribe-form input:focus {
        border-color: #8bca24;
    }

    .subscribe-style-two .subscribe-form button {
        width: 100%;
        padding: 17px 30px 17px 31px;
    }

    .subscribe-style-two .sec-title {
        position: relative;
        display: block;
        margin-bottom: 50px;
    }

.main-header.style-three .outer-container .main-box .nav-outer .outer-box .header-flyout-searchbar i {
    color: #3b3b3b;
}

/*** 

====================================================================
                        Error-page
====================================================================

***/

.error-section {
    position: fixed;
    height: 100%;
    width: 100%;
    display: block;
    background: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 53px 0px 120px 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

    .error-section:before {
        position: absolute;
        content: '';
        background: url(../dist/images/background/error-pattern.png);
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-size: cover;
        background-repeat: no-repeat;
    }

.main-header.style-three.error-page .outer-container .main-box {
    box-shadow: none;
}

.error-section .error-image {
    position: relative;
    display: block;
    margin-bottom: 73px;
}

    .error-section .error-image img {
        position: relative;
        display: inline-block;
        width: auto;
    }

.error-section .sec-title {
    margin-bottom: 69px;
}

.error-section .btn-box .btn-style-two {
    padding: 17px 40px;
}

/* --------------------------------------*/

.form-control {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 14px;
    transition: all 500ms ease;
}

input.form-control {
    height: 60px;
}

textarea.form-control {
    height: 180px;
    resize: none;
}

.form-control:focus {
    background-color: #fff;
    border-color: #8bca24;
    box-shadow: 0 0 0 0.2rem #8bca2440;
    color: #495057;
    outline: 0;
}
