	/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/06/2019 05:43
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../dist/fonts/Flaticon.eot");
  src: url("../dist/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../dist/fonts/Flaticon.woff2") format("woff2"),
       url("../dist/fonts/Flaticon.woff") format("woff"),
       url("../dist/fonts/Flaticon.ttf") format("truetype"),
       url("../dist/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../dist/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-add:before { content: "\f100"; }
.flaticon-checked:before { content: "\f101"; }
.flaticon-tick:before { content: "\f102"; }
.flaticon-play-button:before { content: "\f103"; }
.flaticon-play-arrow:before { content: "\f104"; }
.flaticon-substract:before { content: "\f105"; }
.flaticon-quote:before { content: "\f106"; }
.flaticon-quote-1:before { content: "\f107"; }
.flaticon-digital-marketing:before { content: "\f108"; }
.flaticon-market:before { content: "\f109"; }
.flaticon-dashboard:before { content: "\f10a"; }
.flaticon-pie-chart:before { content: "\f10b"; }
.flaticon-settings:before { content: "\f10c"; }
.flaticon-report:before { content: "\f10d"; }
.flaticon-box:before { content: "\f10e"; }
.flaticon-pc-computer-with-monitor:before { content: "\f10f"; }
.flaticon-bar-chart:before { content: "\f110"; }
.flaticon-magnifying-glass:before { content: "\f111"; }
.flaticon-megaphone:before { content: "\f112"; }
.flaticon-report-1:before { content: "\f113"; }
.flaticon-chart:before { content: "\f114"; }
.flaticon-pie-chart-1:before { content: "\f115"; }
.flaticon-layers:before { content: "\f116"; }
.flaticon-menu:before { content: "\f117"; }
.flaticon-data:before { content: "\f118"; }